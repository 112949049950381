// Contrast to make button work better
$min-contrast-ratio: 3;

// Custom Colours
$lp-orange: #FF6300;
$lp-dark-grey: #262626;
$lp-light-grey: #DDD;
$bs-purple: #AA4291;
$bs-light-accent: #fda6e4;
$bs-lighter-accent: #FFE5FF;

// Colour overrides
$primary: $lp-orange;
$navbar-dark-toggler-border-color: $lp-orange;
$dropdown-link-active-bg: $lp-orange;

// Lambent Fonts
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Gotham Regular', './fonts/Gotham-Medium');
@include font('Gotham Book', './fonts/Gotham-Book');
@include font('Gotham Light', './fonts/Gotham-Light');

.form-label {
  font-weight: bold;
}

// -------
// Put all Bootstrap overrides before here
// -------

@import "../node_modules/bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  background-color: $lp-light-grey;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


nav.bg-dark {
  background-color: $lp-dark-grey;
}

.navbar-dark .navbar-brand {
  color: $lp-orange !important;
}

.crossfade-wrapper {
  overflow: hidden;
  opacity: 1;
  -webkit-transition: max-height 1.2s ease-in;
  -moz-transition: max-height 1.2s ease-in;
  -o-transition: max-height 1.2s ease-in;
  transition: max-height 1.2s ease-in;
  max-height: 1000px;
}

.crossfade-wrapper.cf-hidden {
  -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  -moz-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  opacity: 0 !important;
  max-height: 0px;
}

.col-top-margin {
  margin-top: 10px;
}

.contrib-table {
  text-align: center;
  background-color: #FFF;
  border-radius: 15px;
  border: #000 solid 2px;
  border-collapse: separate;
  border-spacing: 0px;
  overflow: hidden;
}

.contrib-table td {
  border-left:solid black 1px;
  // border-top:solid black 1px;
  overflow: hidden;
}

.contrib-table th {
  border-left:solid black 2px;
  border-bottom:solid black 2px;
  border-top: none;
  background-color: $lp-dark-grey;
  color: white;
  overflow: hidden;
}

.contrib-table td:first-child, th:first-child {
  border-left: none;
}

.contrib-row {
  cursor: pointer;
}

.contrib-row.is-new {
  background-color: #fdc7a5;
}

.contrib-row.new-info {
  background-color: #ffc5ef;
}

.contrib-dl-button {
  text-align: center;
  font-size: 1.5em;
}

.contrib-dl-button a {
  text-decoration: none;
}

.contrib-message {
  text-align: left;
  max-width: none;
}

.modal-90w {
  min-width: 100%;
  border: 0px;
}

.contribl-modal-message-header {
  margin-bottom: 0px;
}

.contrib-modal-message {
  margin-top: 0px;
  font-size: 0.9em;
}

.contrib-modal-videos-container {
  top: 0px;
  bottom: 0px;
}

.contrib-modal-videos {
  position: relative;
  overflow: scroll;
}

.video-item {
  font-size: 0.75em;
}

.editable-ticklist {
  vertical-align: top;
  padding-top: 0.45em;
  color: $secondary;
}

form .row {
  margin-bottom: 0.5em;
}

.filters {
  margin-top: 20px;
  margin-bottom: 10px;
}

.refresh-button {
  color: $lp-orange;
  cursor: pointer;
}


// --------------------
// --- Casting site ---
// --------------------

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.lambent-logo {
  max-width: 200px;
}

.lambent-navbar {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
}

.lambent-nav a {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 15px;
  color: white !important;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.lambent-nav a:hover {
  border-bottom: 1px solid #a5a5a5;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #a5a5a5 !important;
}

.lambent-casting-container {
  font-family: 'Gotham Book', Helvetica, sans-serif !important;
  color: white !important;
  max-width: 800px;
}

.lambent-casting-container h1 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.lambent-casting-container h2 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.lambent-casting-container h3 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.lambent-casting-container h4 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.lambent-casting-container h5 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: #a5a5a5;
  margin-bottom: 0px;
}

.lambent-casting-container u {
  text-decoration-color: rgb(160, 160, 160);
}

.lambent-casting-container a {
  text-decoration-color: #ff62007a;
}

.lambent-casting-form {
  padding: 10px;
  border: 1px 
}

.lambent-casting-form input {
  color: $lp-dark-grey !important;
}

.lambent-casting-form .form-floating {
  color: $lp-dark-grey !important;
  margin-bottom: 20px;
}

.lambent-casting-form .text-muted {
  color: #ccc !important;
}

.lambent-casting-form-select {
  color: $lp-dark-grey !important;
}

.lambent-casting-form-terms-and-conditions {
  font-weight: normal;
  font-size: 13px;
}

.form-red-star {
  color: #da3543;
}

.lambent-footer {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: #ccc;
  margin-top: 40px;
  margin-bottom: 40px;
}

.lambent-footer a {
  text-decoration: none;
  color: #ccc;
}

.lambent-footer-bottom-text {
  font-family: 'Gotham Book', Helvetica, sans-serif !important;
  color: #a5a5a5;
  margin-top: 50px;
}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1em;
  color: #dc3545;
}

.dark-modal .modal-content {
  background-color: #262626;
  color: white !important;
}

.dark-modal .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.dark-modal h4 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.filming-tips-sub {
  margin-top: 0.5em;
  color: #bbb;
  size: 0.75em;
}

.filming-tips-sub u {
  text-decoration-color: rgb(100, 100, 100);
}

.tips-image {
  max-width: 500px;
}

.tips-modal .modal-content {
  background-color: #262626;
}

.tips-modal-header {
  position: fixed;
  top: 0px;
  right: 9px;
  left: 9px;
  background-color: #262626;
  padding: 15px 15px 2px 15px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: solid 1px white;
  overflow: hidden;
  z-index: 1000;
}

.tips-modal-header h1 {
  font-family: 'Gotham Regular', Helvetica, sans-serif !important;
  color: $bs-light-accent;
}

.tips-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 3em;
  width: 3em;
  cursor: pointer;
}

.show-tips-button {
  width: 200px;
}

.bs-question {
  margin-top: 1.5em;
}

.terms-and-conditions {
  background: white;
  color: black;
  border-radius: 10px;
  overflow: scroll;
  overflow-inline: scroll;
  font-size: 0.8em;
  padding: 10px;
  max-height: 33vh;
}

// ------------------------------
// --- Size-dependent styling ---
// ------------------------------

@include media-breakpoint-up(sm) {  
    .lambent-logo {
      max-width: 280px;
    }

    .tips-modal .modal-dialog {
      position: absolute;
      margin-top: 10px;
      right: 10px;
      left: 10px;
      max-width: none;
    }

    .tips-modal-header {
      right: 11px;
      left: 11px;
    }
}

@include media-breakpoint-up(md) {
  .modal-90w {
    min-width: 90%;
  }
}

@include media-breakpoint-up(lg) {
  .contrib-message {
    max-width: 400px;
  }

  .modal-90w {
    min-width: 80%;
  }

  .col-top-margin {
    margin-top: 0px;
  }

  .tips-modal .modal-dialog {
    max-width: 800px;
    margin-top: 40px;
  }
  
  .tips-modal-header {
    position: absolute;
    left: 0px;
    right: 0px;
  }

  .lambent-nav {
    position: absolute;
    right: 15px !important;
    float: right !important;
  }
}

@include media-breakpoint-up(xl) {
  .contrib-message {
    max-width: 600px;
  }

  .modal-90w {
    min-width: 70%;
  }
}

@include media-breakpoint-up(xxl) {
  .contrib-message {
    max-width: 800px;
  }

  .modal-90w {
    min-width: 980px;
  }
}

// To stop things printing
@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  .bs-question {
    color: $lp-dark-grey;
  }
}

// Multiple modal backdrop fix
@for $i from 1 through 6 {
  $zIndexBackdrop:  #{1000 + (5 * $i)};
  $zIndexContent:  #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}